import {Injectable, PLATFORM_ID} from '@angular/core';
import {NavigationStart, Router} from "@angular/router";
import {isPlatformServer, Location} from '@angular/common';
import {OperatorService} from "@app/services/operator.service";
import {SportFacilityService} from "@app/services/sport-facility.service";
import {ScriptService} from "@app/services/script.service";
import {BehaviorSubject} from "rxjs";
import {Capacitor} from "@capacitor/core";
import {Browser} from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class NavigationRouteService {

  /* AUTH */
  private sfsRoute = new BehaviorSubject(null);
  sfsRouteObs = this.sfsRoute.asObservable();

  constructor(
    private router: Router,
    private location: Location,
    private operatorService: OperatorService,
    private sportFacilityService: SportFacilityService,
    private scriptService: ScriptService
  ) {
    this.router.events.subscribe((event) => {
      let startUrl = this.location.path();
      if (event instanceof NavigationStart) {
        const endUrl = event.url
        if (startUrl !== endUrl) {
          if (!localStorage.getItem('cookiePreferences')) {
            localStorage.setItem('cookiePreferences', String(true))
            this.scriptService.loadAnalyticsScript();
          }
        }
      }
    });
  }

  navigate(type: string) {
    if (type == 'home') {
      return `/`;
    } else if (type == 'home-force') {
      return '/';
    } else if (type == 'search') {
      this.router.navigate([`/ricerca/sport`]).then();
    } else if (type == 'search-online') {
      this.router.navigate([`/ricerca/sport/online`]).then();
    } else if (type == 'promo') {
      return `/promo`;
    } else if (type == 'profile') {
      return `/profilo/fitter/myprofile`;
    } else if (type == 'operator-panel') {
      return `pannello-operatore/myprofile`;
    } else if (type == 'check-up') {
      /*this.router.navigate([`/check-up`]).then();*/
      return `/check-up`;
    } else if (type == '..') {
      this.location.back();
    } else if (type == 'check-up-1st-page') {
      return `/check-up/domande`;
    } else if (type == 'check-up-2nd-page') {
      return `/check-up/importanti`;
    } else if (type == 'check-up-3rd-page') {
      return `/check-up/fine`;
    } else if (type == 'thank-you') {
      return `/grazie`;
    } else if (type == 'activity') {
      return `/pannello-operatore/myprofile/mie-attivita`;
    } else if (type == 'operator-panel/company') {
      return `/pannello-operatore/myprofile/sportclub`;
    } else if (type == 'operator-panel/dashboard') {
      return `/pannello-operatore/myprofile/dashboard`;
    } else if (type == 'operator-panel/classroom') {
      return `/pannello-operatore/myprofile/miei-corsi`;
    } else if (type == 'all-promo') {
      return '/promozioni';
    } else if (type == 'about-us') {
      return `/info/chi-siamo`;
    } else if (type == 'media') {
      return `/info/media`;
    } else if (type == 'legal-therms') {
      return `/info/note-legali`;
    } else if (type == 'partner') {
      return `/info/i-nostri-partner`;
    } else if (type == 'presskit') {
      return `/info/press-kit`;
    } else if (type == 'error') {
      return `/errore`;
    } else if (type == 'welcome') {
      return `/benvenuto`;
    } else if (type == 'search-by-sport') {
      return `/ricerca-per-sport`;
    } else if (type == 'search-by-goal') {
      return `/ricerca-per-obiettivo`;
    } else if (type == 'search-by-category') {
      return `/ricerca-per-categoria-sportiva`;
    }
  }


  searchBySport(state?: string, sport?: string) {
    if (state && sport) {
      return `/ricerca-per-sport/` + sport + '/' + state.toLowerCase();
    } else if (state) {
      return `/ricerca-per-sport/` + state.toLowerCase();
    }
  }

  searchByGoal(state?: string, goal?: string) {
    if (state && goal) {
      const goal_changed = goal.replace(/ /g, "-");
      return `/ricerca-per-obiettivo/` + goal_changed + '/' + state.toLowerCase();
    } else if (state) {
      return `/ricerca-per-obiettivo/` + state.toLowerCase();
    }
  }

  searchByCategory(state?: string, category?: string) {
    if (state && category) {
      return `/ricerca-per-categoria-sportiva/` + category + '/' + state.toLowerCase();
    } else if (state) {
      return `/ricerca-per-categoria-sportiva/` + state.toLowerCase();
    }
  }

  navigateSportOutside(sport_facility_slug, sport_facility_sport_id, sport_slug) {
    if (Capacitor.isNativePlatform()) {
      this.router.navigate([`/impianti/${(sport_facility_slug)}/${(sport_facility_sport_id)}/${(sport_slug)}`]).then()
    } else if (!isPlatformServer(PLATFORM_ID)) {
      window.open(`/impianti/${(sport_facility_slug)}/${(sport_facility_sport_id)}/${(sport_slug)}`, '_blank');
    }
  }

  navigateSportFacility(id) {
    this.sportFacilityService.getSportFacility(id).subscribe({
      next: SportFacility => {
        this.router.navigate([`/impianti/${(SportFacility.id)}/${(SportFacility.slug)}`]).then();
      }, error: () => {
        this.router.navigate(['/errore']).then();
      }
    })
  }

  navigateSportClub(id) {
    this.operatorService.getOperatorIdBySportClub(id).subscribe({
      next: operator_id => {
        this.router.navigate([`/profilo/operatore/${(operator_id)}`]).then();
      }, error: () => {
        this.router.navigate(['/errore']).then();
      }
    })
  }

  /*navigateWithId(id, type, outside?: boolean) {
    if (type == 'fitter') {
      return `/profilo/fitter/${(id)}`;
    }if (type == 'sport-facility' && outside) {
      this.sportFacilityService.getSportFacility(id).subscribe({
        next: SportFacility => {
          return `/impianti/${(SportFacility.id)}/${(SportFacility.slug)}`;
          /!*let baseUrl = window.location.href.replace(this.router.url, '');
          window.open(baseUrl + newRelativeUrl, '_blank');*!/
        }, error: err => {
          return `/errore`;
        }
      })
    } else if (type == 'sport-facility') {
      this.sportFacilityService.getSportFacility(id).subscribe({
        next: SportFacility => {
          return `/impianti/${(SportFacility.id)}/${(SportFacility.slug)}`;
        }, error: err => {
          return `/errore`;
        }
      })
    } else if (type == 'sport-club' && outside) {
      this.operatorService.getOperatorIdBySportClub(id).subscribe({
        next: operator_id => {
          return `/profilo/operatore/${(operator_id)}`;
          /!*let baseUrl = window.location.href.replace(this.router.url, '');
          window.open(baseUrl + newRelativeUrl, '_blank');*!/
        }, error: err => {
          return `/errore`;
        }
      })
    } else if (type == 'sport-club') {
      this.operatorService.getOperatorIdBySportClub(id).subscribe({
        next: operator_id => {
          return `/profilo/operatore/${(operator_id)}`;
        }, error: err => {
          return `/errore`;
        }
      })
    } else if (type == 'place-edit') {
      return `/pannello-operatore/myprofile/miei-impianti/modifica/${(id)}`;
    } else if (type == 'fitter' && outside) {
      return `/profilo/fitter/${(id)}`;
      /!*let baseUrl = window.location.href.replace(this.router.url, '');
      window.open(baseUrl + newRelativeUrl, '_blank');*!/
    } else if (type == 'fitter') {
      return `/profilo/fitter/${(id)}`;
    } else if (type == 'article') {
      this.wordpressService.getPostById(id).subscribe({
        next: article => {
          return `/blog/${(article.slug)}`;
        }
      })
    } else if (type == 'blog-author') {
      this.wordpressService.getAuthorById(id).subscribe({
        next: author => {
          return `/blog/a/${(author.slug)}`;
        },
        error: () => {
          this.communicationService.customErrorToast('INVALID_URL', 'danger').then(() => {
            return '/errore';
          })
        }
      })
    } else if (type == 'blog-tag') {
      this.wordpressService.getTagById(id).subscribe({
        next: tag => {
          return `/blog/t/${(tag.slug)}`;
        },
        error: () => {
          this.communicationService.customErrorToast('INVALID_URL', 'danger').then(() => {
            return '/errore';
          })
        }
      })
    } else if (type == 'blog-category') {
      this.wordpressService.getCategoryById(id).subscribe({
        next: category => {
          /!*if (this.router.url.startsWith('/blog')) {
            this.router.navigate([`/blog/c/${(category.slug)}`], {
              queryParamsHandling: ""
            }).then(() => window.location.reload())
          } else {
            this.router.navigate([`/blog/c/${(category.slug)}`], {
              queryParamsHandling: ""
            }).then()
          }*!/
          return `/blog/c/${(category.slug)}`;
        },
        error: () => {
          this.communicationService.customErrorToast('INVALID_URL', 'danger').then(() => {
            return '/errore'
          })
        }
      })
    } else if (type == 'blog') {
      return '/blog';
    }
  }*/

  navigateSearchPlace(place: string) {
    this.router.navigate([`/ricerca/sport/${(place)}`]).then()
    return `/ricerca/sport/${(place)}`;
    /*
        this.statisticService.saveStat('ricerca-sport-' + place, 'Ricerca sport a ' + place, 0)
    */
  }

  navigateSearchSport(sport: string) {
    this.router.navigate([`/ricerca/${(sport)}`]).then()
    return `/ricerca/${(sport)}`;
  }

  openWindowTo(url: string) {
    if (!isPlatformServer(PLATFORM_ID)) {
      if (Capacitor.isNativePlatform) {
        Browser.open({url: url}).then();
      } else {
        window.open(url, "_blank");
      }
    }
  }

  goTo(url: string) {
    if (!isPlatformServer(PLATFORM_ID)) {
      if (Capacitor.isNativePlatform) {
        Browser.open({url: url}).then();
      } else {
        window.location.href = url
      }
    }
  }


}
